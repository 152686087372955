import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import ContentPage from "../../layouts/contentPage"

import Seo from "../../components/seo"

import ProductHero from "../../components/Product/productHero"
import H1 from "../../components/h1"
import ButtonColor from "../../components/buttonColor"
import ProductInfo from "../../components/Product/productInfo"
import ProductPackshot from "../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../components/Product/productPackshotBubbles"
import BubbleBg from "../../components/Product/bubbleBg"
import ProductSidebar from "../../components/Product/productSidebar"
import More from "../../components/more"
import Tabs from "../../components/Tabs/tabs"
import ProductDescription from "../../components/Product/productDescription"
import Relift from "../../images/media/products/relift/relift.svg";
import Ingredients from "../../components/Product/ingredients"
import Ingredient from "../../components/Product/ingredient"
import Effectivness from "../../components/Product/effectivness"
import EffectivnessCard from "../../components/Product/effectivnessCard"
import ActiveIngredients from "../../components/Product/activeIngredients"
import ActiveIngredient from "../../components/Product/activeIngredient"
import DescriptionPackshot from "../../components/Product/descriptionPackshot"
import Container from "../../components/Product/container"






const Product = () => (
    <Layout>
      <Seo title="Liftingujące serum do szyi i dekoltu - Linia Relift" />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="magenta">
                    <H1>Liftingujące serum do szyi i dekoltu</H1>
                    <p>Widoczne napięcie i ujędrnienie</p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/relift/products/liftingujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="liftingujace serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Zapewnia efekt liftingu.</li>
                        <li>Zapobiega wiotczeniu skóry i redukuje zmarszczki.</li>
                        <li>Dotlenia i usprawnia funkcjonowanie komórek.</li>
                        <li>Wspiera mikrobiom i odbudowuje barierę ochronną.</li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-liftingujace-serum-do-szyi-i-dekoltu-50-ml-000000000000407512.html" color="magenta">Kup w Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-relift-liftingujace-serum-do-szyi-i-dekoltu-50-ml.html" color="magenta">Kup w Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-relift-vitamin-a-liftingujace-serum-do-szyi-i-dekoltu-50-ml-0107792" color="magenta">Kup w Gemini</ButtonColor>
                    </Container>
                </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/relift/products/liftingujace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="liftingujace serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="magenta" text="więcej"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar>
                    <Link to="/produkty/wygladzajace-serum">
                        <StaticImage
                             src="../../images/media/products/relift/products/wygladzajace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="wygladzajace serum"
                        />
                    </Link>
                    <Link to="/produkty/odmladzajace-serum">
                        <StaticImage
                             src="../../images/media/products/relift/products/odmladzajace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="odmladzajace serum"
                        />
                    </Link>
                    <Link to="/produkty/ultranaprawcze-serum">
                        <StaticImage
                             src="../../images/media/products/relift/products/ultranaprawcze-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="ultranaprawcze serum"
                        />
                    </Link>

                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="magenta">

                {/* OPIS */}
                <div label="Opis" className="tab-list-active">
                <ProductDescription color="magenta">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">
                                <p>
                                    <strong>Serum widocznie liftinguje wyjątkowo cienką i delikatną skórę szyi i dekoltu, wzmacniając konstrukcję kolagenowo-elastynową, integrując jej włókna i zapobiegając ich rozpadowi.</strong>
                                </p>
                                <p>
                                    W konsekwencji znacząco poprawia się struktura nawet bardzo wiotkiej skóry, staje się ona widocznie ujędrniona i uelastyczniona.
                                </p>
                                <p>
                                    Serum redukuje również głębokie zmarszczki i spowalnia procesy starzenia. Silnie odżywia i intensywnie regeneruje. Ponadto tworzy barierę ochronną, przeciwdziałając odwodnieniu naskórka i silnie go nawilżając.
                                </p>

                                <p>
                                    Efekt to wyraźnie odmłodzona, napięta, wygładzona i zregenerowana skóra.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/relift/products/liftingujace-serum_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="liftingujace serum"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>Sposób użycia:</strong></p>
                                <p>Stosować codziennie rano i wieczorem na oczyszczoną skórę szyi i dekoltu. Tylko do użytku zewnętrznego. Unikać bezpośredniego kontaktu z oczami. Unikać ekspozycji na słońce bez zastosowania preparatu z filtrem UV.</p>
                                <br/>
                                <p><strong>Pojemność:</strong></p>
                                <p>50ml</p>
                                <Relift/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Baza">
                <Ingredients>
                    <Ingredient name="Retinol z adenozyną" desc="To połączenie składników, które przyspiesza odbudowę skóry 
usuwając stare komórki i zastępując je nowymi. 
Ponadto pobudza produkcję kolagenu i elastyny, 
spłycając istniejące zmarszczki i chroniąc przed 
powstawaniem nowych. W konsekwencji skóra 
jest wygładzona, napięta i rozświetlona." />
                    <Ingredient name="Kompleks dotleniających minerałów" desc="Składnik, który wiąże cząsteczki tlenu zwiększając ich dostępność w skórze oraz stymuluje metabolizm komórkowy. Dzięki czemu komórki skóry otrzymują niezbędną energię, zaczynają prawidłowo funkcjonować i regenerować się. W efekcie znacznie poprawia się wygląd skóry, staje się ona zdrowa i promienna." />
                    <Ingredient name="Synbiotyk" desc="To kompleks wzajemnie uzupełniającego się probiotyku i prebiotyku, który wzmacnia naturalną warstwę ochronną skóry - mikrobiom, chroniąc przed niekorzystnymi czynnikami zewnętrznymi. Dodatkowo wykazuje działanie nawilżające, odżywcze i wygładzające." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Składniki aktywne">
                <ActiveIngredients>
                    <ActiveIngredient name="Kwas hialuronowy" desc="Działa silnie nawilżająco i hamuje procesy starzenia. Tworzy warstwę okluzyjną na skórze, zmniejszając transepidermalną utratę wody (TEWL)." />
                    <ActiveIngredient name="Olej z orzechów macadamia" desc="Składnik, który tworzy hydrofobowy film na skórze, który odpowiada za natłuszczanie, zmiękczanie, wygładzenie, a także pośrednie nawil­żanie skóry poprzez ograniczenie transepidermalnej utracie wody (TEWL). Dodatkowo działa ujędrniająco i uelastyczniająco." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Skuteczność">
                <Effectivness>
                    <EffectivnessCard color="magenta" percentage="90" desc="badanych potwierdza, że produkt wykazuje skuteczne działanie przeciwzmarszczkowe"/>
                    <EffectivnessCard color="magenta" percentage="100" desc="badanych potwierdza, że po zastosowaniu skóra staje się bardziej miękka"/>
                    <EffectivnessCard color="magenta" percentage="93" desc="badanych potwierdza, że regularne stosowanie zwiększa napięcie skóry zapewniając efekt liftingu"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
